import styled from "styled-components";

export const Container = styled.section`
  a {
    color: #f58224;
    font-weight: 600;
  }
`

export const TitlePage = styled.section`
  font-size: 32px;
  line-height: 34px;
  color: #282525;
  font-family: "PoppinsBold", sans-serif;
`;

export const BigBoxAgrega = styled.div`
  width: 598px;
  border: 1px solid #ececec;
  border-radius: 10px;

  @media (max-width: 960px) {
    width: 100%;
    padding: 20px 0;
  }
`;

export const TitleBigBox = styled.h2`
  font-family: "PoppinsBold", sans-serif;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 20px;
  padding: 0px 30px;
`;

export const TextBigBox = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: #555555;
  padding: 0px 30px;
`;

export const ImgBigBox = styled.div`
  padding: 30px 40px 0px 0;
  @media (max-width: 500px) {
    display: none;
  }
  .img-box {
    width: 300px;
    height: 246px;
    max-width: initial;
  }
`;

export const ButtonPoints = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  text-align: left;
  background: #428603;
  border-radius: 18px;
  letter-spacing: 0px;
  max-width: 356px;
  margin: 10px 0px;
  padding-left: 30px;
  display: none;
  p {
    font-family: "PoppinsBold", sans-serif;
    color: #fff;
    font-size: 10px;
    line-height: 16px;
  }
`;

export const RedLink = styled.a`
  font-family: "PoppinsBold", sans-serif;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  color: #ec0818;
  padding: 0px 30px;
`;

export const BoxCampaign = styled.div`
  width: 289px;
  border: 1px solid #ececec;
  border-radius: 10px;
  @media (max-width: 960px) {
    margin: auto;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const TitleBox = styled.div`
  font-family: "PoppinsBold", sans-serif;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  color: #f58224;
  padding: 40px;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const RightSideDiv = styled.div`
  width: 100%;

  @media (min-width: 1280px) {
    width: 30%;
  }

  .button-rescue-now {
    font-family: "PoppinsBold", sans-serif;
    font-size: 1rem;
    background: #fc3229;
    border: 2px solid #fc3229;
    border-radius: 30px;
    color: #fefefe;
    height: 50px;
    outline: 0;
    width: 90%;

    cursor: pointer;

    &:hover {
      opacity: 0.7;
      transition: 0.2s;
    }
  }
`;

export const BannerActions = styled.button`
  border: none;
  background: transparent;

  cursor: pointer;
`;
