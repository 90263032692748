import { useState } from "react";
import {
  AddCultureButton,
  Box,
  ButtonContainer,
  Container,
  Footer,
  Input,
  Label,
  Select,
} from "./styled";

export const AdditionalInformation = () => {
  const [acceptedAgrega, setAcceptedAgrega] = useState(false);
  return (
    <>
      <Container>
        <Box>
          <Label htmlFor="cultures">
            Cultura <span>*</span>
          </Label>
          <Select id="cultures" name="cultures">
            <option value="">Sua cultura</option>
          </Select>
        </Box>
        <Box>
          <Label htmlFor="cultures">
            Área plantada (ha)<span>*</span>
          </Label>
          <Input type="text" id="hectares" name="hectares" />
          <AddCultureButton>
            <i className="fa fa-plus" aria-hidden="true"></i>
            Adicionar cultura
          </AddCultureButton>
        </Box>
        <Box>
          <Label htmlFor="cultures">
            Canal <span>*</span>
          </Label>
          <Select id="cultures" name="cultures">
            <option value="">Selecione</option>
          </Select>
        </Box>
        <Box>
          <Label htmlFor="cultures">Indicação/Promoção</Label>
          <Input type="text" id="cupom" name="cupom" />
        </Box>
        <Footer>
          <div>
            <label htmlFor="acceptedAgrega">
              <input
                type="checkbox"
                id="acceptedAgrega"
                name="acceptedAgrega"
                checked={acceptedAgrega}
                onChange={() => setAcceptedAgrega(!acceptedAgrega)}
              />
              <span className="checkmark acceptedAgrega"></span>
              <span className="acceptedAgrega">
                <b>*</b> Eu li e concordo com o               <a
                  href="https://staccobasfagregaprd.blob.core.windows.net/agrega-regulamento/2023.09.14_NOVO%20AGREGA_VF.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  regulamento Agrega
                </a>
              </span>
            </label>
          </div>
        </Footer>
      </Container>
      <ButtonContainer>
        <button>Salvar</button>
      </ButtonContainer>
    </>
  );
};
