/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { HttpClient } from "data/protocols/http/http-client";
import { StorageHelper } from "utils/functions/storage/storage";
import { RedirectUserAgregaLogged } from "./redirect-user-agrega-logged";
import { CampaignListHttpResponse } from "domain/models/campaign/list";

const storageHelper = new StorageHelper();
export class Http<HttpParams = any, HttpResponse = any>
	implements HttpClient<HttpParams, HttpResponse>
{
	async request(
		params?: HttpParams
	): Promise<{ response: HttpResponse; statusCode: number; success: boolean }> {
		const response: AxiosResponse<HttpResponse> = await axios.request(
			params as AxiosRequestConfig
		);
		return {
			response: response.data,
			statusCode: response.status,
			success: response.status >= 200 && response.status <= 299,
		};
	}
}
const Subscription: AxiosRequestConfig = {
	headers: {
		["Ocp-Apim-Subscription-Key"]: String(
			process.env.REACT_APP_SUBSCRIPTION_KEY
		),
		["X-Session-Id"]: storageHelper.get("sessionKey"),
	},
};

const SubscriptionAgregaAdmin: AxiosRequestConfig = {
	headers: {
		["Ocp-Apim-Subscription-Key"]: String(
			process.env.REACT_APP_AGREGA_ADMIN_SUBSCRIPTION_KEY
		),
		["X-Session-Id"]: storageHelper.get("sessionKey"),
	},
};

const SubscriptionParticipant: AxiosRequestConfig = {
	headers: {
		Authorization: `Bearer ${storageHelper.get("token")}`,
		["Ocp-Apim-Subscription-Key"]: String(
			process.env.REACT_APP_AGREGA_SUBSCRIPTION_KEY
		),
		["X-Session-Id"]: storageHelper.get("sessionKey"),
	},
};

export const api = {
	checkCampaignExtractExists: async (
		document_number: string | number | null | undefined,
		id: string | undefined
	) => {
		try {
			const response = await axios.head(
				`${process.env.REACT_APP_EXTRACT_BLOB_URL}${document_number}_${id}.xlsx`
			);
			return response.status === 200;
		} catch {
			return false;
		}
	},
	fetchAllCampaigns: async (
		queryParams: string
	): Promise<CampaignListHttpResponse> => {
		const campaignsList = await axios.get<CampaignListHttpResponse>(
			`${process.env.REACT_APP_API_CONECTA_AG_URL}/conectapontosbackend/campaign/getPaginated?Sort.Sortby=startDate&Sort.Direction=Desc&${queryParams}`,
			Subscription
		);

		return campaignsList?.data;
	},
	fetchCampaign: async (id: string | undefined) => {
		return axios.get(
			`${process.env.REACT_APP_API_CONECTA_AG_URL}/conectapontosbackend/campaign/detail/getByCampaignId/${id}`,
			Subscription
		);
	},
	fetchOptins: async (document_number: string | number | null | undefined) => {
		return axios.get(
			`${process.env.REACT_APP_BASE_URL_API}/participant/participants/optin/${document_number}`,
			SubscriptionParticipant
		);
	},
	getBanners: async () => {
		return axios.get(
			`${process.env.REACT_APP_BASE_URL_API}admin/banners`,
			SubscriptionAgregaAdmin
		);
	},
	postOptinCampaing: async (form: {
		ConectaProgramId: string | number | undefined | null;
		DocumentNumber: string | number | undefined | null;
		PromotionCode: string | null;
	}) => {
		return axios.post(
			`${process.env.REACT_APP_BASE_URL_API}participant/participants/optinProgramConectaPontos`,
			form,
			SubscriptionParticipant
		);
	},
	redirectUserAgregaLogged: async (userParams: RedirectUserAgregaLogged) => {
		const httpResult = await axios.post(
			`${process.env.REACT_APP_BASE_URL_API}/sso/auth`,
			{ ...userParams, secretKey: process.env.REACT_APP_API_SECRET_KEY },
			{
				headers: {
					["Ocp-Apim-Subscription-Key"]: String(
						process.env.REACT_APP_AGREGA_SUBSCRIPTION_KEY
					),
					["X-Session-Id"]: storageHelper.get("sessionKey"),
				},
			}
		);

		return httpResult?.data?.response?.data?.redirectUrl;
	}
};
