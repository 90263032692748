import styled from "styled-components";

export const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Kavoon&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Varela+Round&display=swap");

  width: 60%;
  position: absolute;
  z-index: 100;
  right: 4%;
  bottom: 70%;

  .cards {
    position: relative;
  }

  .text-card {
    font-size: 0.75rem;
    margin-right: 0;
  }

  .text-card-resgate {
    margin-right: 1rem;
    margin-left: 0.5rem;
  }

  .title-card {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 1.15rem;
    font-weight: bold;
    width: 61%;
  }

  .arrow-class {
    width: 5%;
    z-index: 1;
    margin-right: -3%;

    img {
      width: 3.5rem;
    }
  }

  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .card-body {
      gap: 10px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-radius: 20px;
      height: 8rem;
      width: 100%;
      justify-content: space-around;

      .card-steps {
        gap: 1rem;
        margin-left: -1.75rem;

        span {
          color: #d22;
          font-family: "Franklin Gothic Medium", "Arial Narrow", Arial,
            sans-serif;
          font-weight: bold;
          font-size: 2.5rem;
        }
      }

      :hover {
        -webkit-box-shadow: 10px 10px 123px -9px rgba(168,168,168,1);
        -moz-box-shadow: 10px 10px 123px -9px rgba(168,168,168,1);
        box-shadow: 10px 10px 123px -9px rgba(168,168,168,1);
        transform: scale(1.1);
        transition: 0.4s ease-in-out;

        cursor: pointer;
      }
    }
  }

  @media (max-width: 360px) {
    right: 12% !important;
  }
  @media (max-width: 430px) {
    width: auto;
    bottom: 79% !important;

    .cards {
      width: 60% !important;
    }
  }
  @media (min-width: 431px) and ((max-width: 800px)) {
    bottom: 67%;
  }
  @media (max-width: 820px) {
    right: 14%;
    width: 100%;
    margin: auto;

    .cards {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      width: 34%;
      margin: 0 auto 0 33%;
    }

    .card-body {
      -webkit-box-shadow: -4px 14px 28px -10px rgba(163, 163, 163, 1);
      -moz-box-shadow: -4px 14px 28px -10px rgba(163, 163, 163, 1);
      box-shadow: -4px 14px 28px -10px rgba(163, 163, 163, 1);
    }

    .arrow-class {
      width: 5%;
      z-index: 1;
      margin-right: 38% !important;
      margin-top: -16%;
      margin-bottom: -2rem;

      img {
        transform: rotate(90deg);
      }
    }
  }
  @media (min-width: 768px) and (max-width: 996px){
    bottom: 85%;
    height: 6rem !important;
    right: 8%;

    .cards {
      width: 42%;
    }
  }

  @media (min-width: 1024px) and (max-width: 1195px) {
    .arrow-class {
      display: none;
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0.5rem;
      margin-left: 10rem;
    }
    bottom: 83%;
  }

  @media (min-width: 1280px){
    width: 55%;
    right: 4%;
    bottom: 84%;
  }

  @media (min-width: 1920px){
    bottom: 79.5%;

    .card-steps {
      margin-left: -5.75rem;
    }

    .text-card {
      font-size: 0.9rem;
      margin-right: 3rem;
    }

    .card-steps > span {
      font-size: 3rem;
    }

    .title-card {
      font-size: 1.4rem;
      font-weight: bold;
      width: 62%;
    }

    .text-card-resgate {
      margin-right: 4rem;
    }

    .arrow-class {
      width: 4%;
      margin-right: -2.5%;

      img {
        width: 5rem;
      }
    }
  }

  @media (min-width: 2550px) {
    width: 40%;
    right: 13%;
    bottom: 78%;

    .title-card {
      width: 65%;
    }

    .card-steps {
      margin-left: -5.75rem !important;
    }

    .second-card {
      margin-left: -6.9rem !important;
    }
  }
`;
