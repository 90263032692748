import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    margin: 1rem 0;
`;
export const Box = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
export const Label = styled.label`
    font-size: 0.85rem;
    margin-left: 0.75rem;
`
export const Input = styled.input`
    padding: 0.5rem;
    padding-left: 1rem;
    border-radius: 1rem;
    border: 1px solid #ccc;
    width: 90%;

    :focus {
        transition: 0.2s;
        outline: none;
        border: 1px solid #fc3229;
    }
`
export const Select = styled.select`
    padding: 0.5rem;
    padding-left: 1rem;
    border-radius: 1rem;
    border: 1px solid #ccc;
    width: 90%;
    color: #616161;

    :focus {
        transition: 0.2s;
        outline: none;
        border: 1px solid #fc3229;
    }
`
export const Button = styled.button`
    font-family: "PoppinsBold", sans-serif;
    background-color: #fc3229;
    border-radius: 2rem;
    border: none;
    color: #fefefe;
    height: 2.5rem;
    outline: 0;
    width: 40%;
    margin: 1rem auto;

    cursor: pointer;

    &:hover {
      transition: 0.2s;
      letter-spacing: 1px;
    }
`