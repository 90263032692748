import { useState } from "react";
import { Container, PaginationContainer, TableContainer, Tbody, Thead } from "./styled";
import { TableProps } from "./types";
import { mockDistributors, mockProducts } from "./mock";

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

export const Table = (props: TableProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const data = props.type === "products" ? mockProducts : mockDistributors;
  const totalPages = Math.ceil(data.length / rowsPerPage);

  const currentData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const startIndex = (currentPage - 1) * rowsPerPage + 1;
  const endIndex = Math.min(currentPage * rowsPerPage, data.length);

  const handleFirstPage = () => setCurrentPage(1);
  const handleLastPage = () => setCurrentPage(totalPages);
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <Container>
      <TableContainer>
        <Thead>
          {props.type === "products" && (
            <tr>
              <th>Categoria</th>
              <th>Produto</th>
              <th>Ponto</th>
            </tr>
          )}
          {props.type === "distributors" && (
            <tr>
              <th>UF</th>
              <th>Distribuidor</th>
              <th>Cidade</th>
              <th>Bairro</th>
              <th>Logradouro</th>
              <th>Número</th>
            </tr>
          )}
        </Thead>
        <Tbody>
          {currentData.map((item: any, index: number) => (
            <tr key={index}>
              {props.type === "products" ? (
                <>
                  <td>{item.category}</td>
                  <td>{item.product}</td>
                  <td>{item.points}</td>
                </>
              ) : (
                <>
                  <td>{item.uf}</td>
                  <td>{item.distributor}</td>
                  <td>{item.city}</td>
                  <td>{item.neighborhood}</td>
                  <td>{item.publicPlace}</td>
                  <td>{item.number}</td>
                </>
              )}
            </tr>
          ))}
        </Tbody>
      </TableContainer>
      <PaginationContainer>
          <button onClick={handleFirstPage} disabled={currentPage === 1}>
            <KeyboardDoubleArrowLeftIcon />
          </button>
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>
            <KeyboardArrowLeftIcon />
          </button>
          <span>
            {startIndex}-{endIndex} de {data.length}
          </span>
          <button onClick={handleNextPage} disabled={currentPage === totalPages}>
            <KeyboardArrowRightIcon />
          </button>
          <button onClick={handleLastPage} disabled={currentPage === totalPages}>
            <KeyboardDoubleArrowRightIcon />
          </button>
      </PaginationContainer>
    </Container>
  );
};
