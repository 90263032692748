/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from "react";
import { useTitle } from "hooks";
import {
	ContainerHome,
	TextHome,
	BannerHome,
	ContainerDefault,
 ContentHome} from "./styled";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
	ModalRedirectComplementAccount,
	ModalUserIsLogged,
	ModalUserIsNotLogged,
} from "../Modal";
import { HttpRegistrationRedirect } from "services/account-user/redirect";
import { StorageHelper } from "utils/functions/storage/storage";
import { api, Http } from "data/protocols/services/api";
import OptinComunication from "../Modal/OptinComunication/OptinComunication";
import ModalLogout from "modal/modal-logout";
import { CardsInBanner } from "./CardsInBanner";
import { ToastContainer } from "react-toastify";
import { BannersImagesList, BannersList } from "domain/models/banners/list";


const Home: FC = () => {
	const { user } = useSelector((state: RootState) => state);
	const navigate = useNavigate();
	const [modalUserIsNotLogged, setModalUserIsNotLogged] =
		useState<boolean>(false);
	const [modalUserIsLogged, setModalUserIsLogged] = useState<boolean>(false);
	const [
		modalRedirectComplementAccountIsOpen,
		setModalRedirectComplementAccountIsOpen,
	] = useState<boolean>(false);
	const [showModalLogout, setShowModalLogout] = useState<boolean>(false);
	const [modalOptionComunication, setModalOptionComunication] =
		useState<boolean>(false);
	const [queryParamValue, setQueryParamValue] = useState<string>("");
	const [isRedirect, setIsRedirect] = useState<boolean>(false);
	const [banners, setBanners] = useState<BannersImagesList>({
		desktopBannerUrl: "",
		mobileBannerUrl: "",
		tabletBannerUrl: "",
		wideBannerUrl: "",
	});
	useTitle("Conecta Pontos");
	const http = new Http();
	const storageHelper = new StorageHelper();

	const httpRegistrationRedirect = new HttpRegistrationRedirect(
		http,
		storageHelper
	);

	useEffect(() => {
		const redirectUser = localStorage.getItem("user_redirect_mktplace");
		if (redirectUser) {
			setIsRedirect(true);
		} else {
			setIsRedirect(false);
		}
	}, [isRedirect]);

	useEffect(() => {
        (function(c: any, l: Document, a: string, r: string, i: string, y?: Element | null){
            c[a] = c[a] || function(...args: any[][]){(c[a].q = c[a].q || []).push(args)};
            const t = l.createElement(r) as HTMLScriptElement;
            t.async = true;
            t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0];
            if (y && y.parentNode) {
                y.parentNode.insertBefore(t, y);
            }
        })(window, document, "clarity", "script", "mm69rd7xp9");
    }, []);

	useEffect(() => {
		window.scrollTo({ behavior: "smooth", top: 0 });
	}, []);

	useEffect(() => {
		api.getBanners().then((response) => {
			setBanners({
				desktopBannerUrl: response.data.data.desktopBannerUrl ?? "",
				mobileBannerUrl: response.data.data.mobileBannerUrl ?? "",
				tabletBannerUrl: response.data.data.tabletBannerUrl ?? "",
				wideBannerUrl: response.data.data.wideBannerUrl ?? ""
			});
		})
	}, []);

	function goToProgram() {
		navigate("/programas-parceiros");
	}

	const handleCloseModal = (): void => {
		setModalRedirectComplementAccountIsOpen(false);
	};

	async function goToMktPlaceWithCategories(link: string) {
		if (!user.is_logged) {
			setModalUserIsNotLogged(true)
			return;
		}

		if (!user.opt_in_terms_and_privacy || !user.opt_in_points) {
			setModalRedirectComplementAccountIsOpen(true);
			return;
		}

		if (user.opt_in_comunication === null) {
			setModalOptionComunication(true);
			return;
		}
		try {
			const rootLinkWithCustomURL = `showcase/?identifier=${link}`
			const log = await httpRegistrationRedirect.getUrlRedirect({
				data: {
					documentNumber: user.document_number as string,
					email: user.email as string,
					routeCatalog: rootLinkWithCustomURL
				},
			});
			setIsRedirect(true);
			localStorage.setItem("user_redirect_mktplace", "isRedirect");
			window.location.href = log.response.data;
		} catch (error: any) {
			setShowModalLogout(error?.response?.status === 401);
		}
	}

	return (
		<>
			<ModalLogout isOpen={showModalLogout} />
			<ContainerHome>
				<p className="slogan" translate="no">
					Sua lavoura <b>conectada</b>. Seu negócio <b>competitivo.</b>
					{!user.is_logged && (
						<Link
							className="slogan-button"
							to={`${process.env.REACT_APP_CONECTA_AG_URL}/customer/account/create/`}
						>
							Cadastre-se
						</Link>
					)}
				</p>
				<ContentHome id="home-content">
					<TextHome onClick={goToProgram}>
						<h4>Bem vindo ao</h4>
						<img className="logo-conecta-home" src="img/logo-conecta-ag.png" />
						<h2>Aqui seu ponto vale mais!</h2>
						<p translate="no">
							<b>Conecta Pontos</b> é o <b>programa de fidelidade</b> do
							Conecta.ag que gera valor para empresas parceiras, canais de
							distribuição e oferece oportunidades para o produtor ganhar pontos,
							que poderão ser trocados por soluções agrícolas, produtos para o seu
							negócio e família.
						</p>
					</TextHome>
					<BannerHome $banners={banners} />
					<CardsInBanner 
						$functions={{
							setIsRedirect,
							setModalOptionComunication,
							setModalRedirectComplementAccountIsOpen,
							setModalUserIsNotLogged,
						}}
						$redirectCatalog={httpRegistrationRedirect}
						$user={user}
					/>
					<ToastContainer />
				</ContentHome>
			</ContainerHome>
			<ContainerDefault className="pt-4">
				<h2>Use seus pontos para resgatar produtos e serviços</h2>
			</ContainerDefault>

			<ContainerDefault className="pb-7">
				<img
					src="img/agricultura-de-precisao.png"
					alt="agricultura-de-precisao"
					className="img-categories pointer"
					onClick={() =>
						goToMktPlaceWithCategories("agricultura-de-precisao&type=5") as any
					}
				/>
				<img
					src="img/consultoria-tecnica.png"
					alt="consultoria-tecnica"
					className="img-categories pointer"
					onClick={() =>
						goToMktPlaceWithCategories("consultoria-tecnica&type=5") as any
					}
				/>
				<img
					src="img/equipamentos-agricolas.png"
					alt="equipamentos-agricolas"
					className="img-categories pointer"
					onClick={() =>
						goToMktPlaceWithCategories("equipamentos-agricolas&type=5") as any
					}
				/>
				<img
					src="img/tecnologia-de-aplicaao.png"
					alt="tecnologia-de-aplicaao"
					className="img-categories pointer"
					onClick={() =>
						goToMktPlaceWithCategories("tecnologia-de-aplicacao&type=5") as any
					}
				/>
			</ContainerDefault>

			<div className="section-gray pt-5 pb-7">
				<ContainerDefault>
					<h2>Perguntas frequentes</h2>
				</ContainerDefault>

				<ContainerDefault>
					<div className="question-box">
						<p className="question-title" translate="no">Como funciona o Conecta Pontos?</p>
						<p className="question-answer">
							Conecta Pontos é o programa de fidelidade do ecossistema
							Conecta.ag, que gera valor para empresas parceiras, canais de
							distribuição e oferece oportunidades no dia a dia do produtor para
							ganhar pontos que poderão ser usados para trocar por soluções
							agrícolas, produtos para o seu negócio e família.
						</p>
					</div>

					<div className="question-box">
						<p className="question-title">
							Como faço para participar do Conecta Pontos?
						</p>

						<p className="question-answer">
							É simples! Basta se cadastrar em nosso site Conecta.ag . O acesso
							à plataforma Conecta Pontos está disponível apenas para pessoas
							físicas e maiores de 18 anos.
						</p>
					</div>

					<div className="question-box">
						<p className="question-title">Como ganhar pontos?</p>

						<p className="question-answer">
							Ganhar pontos no programa é muito fácil! Basta comprar produtos
							elegíveis nos programas participantes de acordo com os respectivos
							regulamentos.
						</p>
					</div>
				</ContainerDefault>

				<ContainerDefault>
					<div className="question-box">
						<p className="question-title">
							Quais são os canais distribuidores e programas parceiros que
							participam do programa?
						</p>

						<p className="question-answer">
							Atualmente o programa participante do Conecta Pontos é o AGREGA.
							Em breve teremos novidades!
						</p>
					</div>

					<div className="question-box">
						<p className="question-title">
							Tenho uma conta única para o meu CPF?
						</p>

						<p className="question-answer">
							Sim, o Conecta consolida todos os pontos acumulados nos programas
							participantes apresentados no Extrato do Participante no Conecta
							Pontos (conecta.webpremios.digital).
						</p>
					</div>

					<div className="question-box">
						<p className="question-title">Como posso trocar meus pontos?</p>

						<p className="question-answer">
							Os pontos acumulados poderão ser trocados por produtos ou serviços
							(chamamos de "Resgate de Pontos") de acordo com a quantidade de
							pontos exigida através do nosso catálogo de prêmios disponível no
							site.
						</p>
					</div>
				</ContainerDefault>

				<ContainerDefault>
					<div className="question-small-box">
						<Link
							to={`${process.env.REACT_APP_CONECTA_AG_URL}/faq`}
							target="_blank"
						>
							<img src="img/icon-question.svg" className="img-small-box" />

							<p className="text-small-box" translate="no">
								Para mais informações, <br />
								veja as <strong>Perguntas frequentes.</strong>
							</p>
						</Link>
					</div>

					<div className="question-small-box">
						<Link
							to={`${process.env.REACT_APP_CONECTA_AG_URL}/regulamento-fidelidade`}
							target="_blank"
						>
							<img src="img/icon-tasks.svg" className="img-small-box" />

							<p className="text-small-box" translate="no">
								Para todos os detalhes do programa,{" "}
								<strong>leia o Regulamento.</strong>
							</p>
						</Link>
					</div>

					<div className="question-small-box">
						<Link
							to={`${process.env.REACT_APP_CONECTA_AG_URL}/contato`}
							target="_blank"
						>
							<img src="img/icon-doubts.svg" className="img-small-box" />

							<p className="text-small-box">
								Ficou com alguma dúvida? <br />
								<strong>Fale conosco.</strong>
							</p>
						</Link>
					</div>
				</ContainerDefault>
				<ModalUserIsNotLogged
					queryParamValue={queryParamValue}
					isOpen={modalUserIsNotLogged}
					handleCloseModal={() => setModalUserIsNotLogged(false)}
				/>

				<ModalUserIsLogged
					queryParamValue={queryParamValue}
					isOpen={modalUserIsLogged}
					handleCloseModal={() => setModalUserIsLogged(false)}
				/>

				<ModalRedirectComplementAccount
					isOpen={modalRedirectComplementAccountIsOpen}
					handleCloseModal={handleCloseModal}
				/>
				<OptinComunication
					isOpen={modalOptionComunication}
					handleCloseModal={() => setModalOptionComunication(false)}
				/>
			</div>
		</>
	);
};

export default Home;
