import { toast } from "react-toastify";
import { Container } from "./styled";
import { useNavigate } from "react-router-dom";
import { UserState } from "store/domain";
import { HttpRegistrationRedirect } from "services/account-user/redirect";
import { CardStep } from "./CardStep";
import { useCallback } from "react";

interface CardsButtonProps {
  $functions: {
    setIsRedirect: (isRedirect: boolean) => void,
    setModalOptionComunication: (modalOptionComunication: boolean) => void,
    setModalRedirectComplementAccountIsOpen: (modalRedirectComplementAccountIsOpen: boolean) => void,
    setModalUserIsNotLogged: (modalUserIsNotLogged: boolean) => void,
  },
  $redirectCatalog: HttpRegistrationRedirect,
  $user: UserState;
}

interface RedirectResponse {
  response: {
    data: string;
  };
}

export const CardsInBanner = (props: CardsButtonProps) => {
  const {
    $functions: {
        setIsRedirect,
        setModalOptionComunication,
        setModalRedirectComplementAccountIsOpen,
        setModalUserIsNotLogged
    },
    $redirectCatalog,
    $user
  } = props;
  const navigate = useNavigate();

  const showToast = (type: "warning" | "error", message: string, toastId: string) => {
    toast[type](message, {
      position: toast.POSITION.TOP_RIGHT,
      toastId,
    });
  };
  

  const handleGoToSign = useCallback(() => {
    if ($user.is_logged) {
      showToast("warning", "Você já está cadastrado", "is_registered");
    } else {
      window.location.href = `${process.env.REACT_APP_CONECTA_AG_URL}/customer/account/create`;
    }
  }, [$user.is_logged]);
  

  const handleGoToPrograms = () => {
    navigate("/programas-parceiros");
  };

  async function goToMktPlace() {
    if (!$user.is_logged) {
      setModalUserIsNotLogged(true);
      return;
    }

    if (!$user.opt_in_terms_and_privacy || !$user.opt_in_points) {
      setModalRedirectComplementAccountIsOpen(true);
      return;
    }

    if ($user.opt_in_comunication === null) {
      setModalOptionComunication(true);
      return;
    }
    try {
      const log: RedirectResponse = await $redirectCatalog.getUrlRedirect({
        data: {
          documentNumber: $user.document_number as string,
          email: $user.email as string,
        },
      });
      setIsRedirect(true);
      localStorage.setItem("user_redirect_mktplace", "isRedirect");
      window.location.href = log.response.data;
    } catch (error) {
      showToast("error", "Não foi possível redirecionar para o catálogo.", "error_redirect_mktplace");
      console.error(error);
    }
  }

  return (
    <Container>
      <div className="cards">
        <CardStep 
          stepNumber="01"
          title="Faça o seu cadastro"
          description="Crie sua conta em poucos passos. É fácil, rápido e grátis."
          onClick={handleGoToSign}
        />
        <div className="arrow-class">
          <img src="img/arrow-ball.png" className="arrow-ball" alt="" />
        </div>
        <CardStep 
          stepNumber="02"
          title="Junte pontos"
          className="second-card"
          description="Aceite o regulamento e participe das campanhas e programas."
          onClick={handleGoToPrograms}
        />
        <div className="arrow-class">
          <img src="img/arrow-ball.png" className="arrow-ball" alt="" />
        </div>
        <div className="card-body" onClick={goToMktPlace}>
          <div className="card-steps">
            <span>03</span>
            <p className="title-card">Troque <br /> seus pontos</p>
          </div>
          <div>
            <p className="text-card text-card-resgate">Resgate produtos e serviços em nosso catálogo.</p>
          </div>
        </div>
      </div>
    </Container>
  );
};
