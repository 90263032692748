interface CardStepProps {
  className?: string;
  description: string;
  onClick: () => void;
  stepNumber: string;
  title: string;
}

export const CardStep = ({
  stepNumber,
  title,
  className,
  description,
  onClick,
}: CardStepProps) => (
  <div className="card-body" onClick={onClick}>
    <div className={`card-steps ${className}`}>
      <span>{stepNumber}</span>
      <p className="title-card">{title}</p>
    </div>
    <div>
      <p className="text-card">{description}</p>
    </div>
  </div>
);
