export const mockProducts = [
  {
    category: "Alimentação",
    id: 1,
    points: 10,
    product: "Café",
  },
  {
    category: "Alimentação",
    id: 2,
    points: 10,
    product: "Café",
  },
  {
    category: "Alimentação",
    id: 3,
    points: 10,
    product: "Café",
  },
  {
    category: "Alimentação",
    id: 4,
    points: 10,
    product: "Café",
  },
  {
    category: "Alimentação",
    id: 5,
    points: 10,
    product: "Café",
  },
  {
    category: "Alimentação",
    id: 6,
    points: 10,
    product: "Café",
  },
  {
    category: "Alimentação",
    id: 7,
    points: 10,
    product: "Café",
  },
  {
    category: "Alimentação",
    id: 8,
    points: 10,
    product: "Café",
  },
  {
    category: "Alimentação",
    id: 9,
    points: 10,
    product: "Café",
  },
  {
    category: "Alimentação",
    id: 10,
    points: 10,
    product: "Café",
  },
  {
    category: "Alimentação",
    id: 11,
    points: 10,
    product: "Café",
  },
  {
    category: "Alimentação",
    id: 12,
    points: 10,
    product: "Café",
  },
  {
    category: "Alimentação",
    id: 13,
    points: 10,
    product: "Café",
  },
  {
    category: "Alimentação",
    id: 14,
    points: 10,
    product: "Café",
  },
  {
    category: "Alimentação",
    id: 15,
    points: 10,
    product: "Café",
  },
];
export const mockDistributors = [
    {
        city: 'São Paulo',
        distributor: 'Distribuidor',
        neighborhood: 'Bairro',
        number: 125,
        publicPlace: 'Logradouro',
        uf: 'SP'
    },
    {
        city: 'São Paulo',
        distributor: 'Distribuidor',
        neighborhood: 'Bairro',
        number: 125,
        publicPlace: 'Logradouro',
        uf: 'SP'
    },
    {
        city: 'São Paulo',
        distributor: 'Distribuidor',
        neighborhood: 'Bairro',
        number: 125,
        publicPlace: 'Logradouro',
        uf: 'SP'
    },
    {
        city: 'São Paulo',
        distributor: 'Distribuidor',
        neighborhood: 'Bairro',
        number: 125,
        publicPlace: 'Logradouro',
        uf: 'SP'
    },
    {
        city: 'São Paulo',
        distributor: 'Distribuidor',
        neighborhood: 'Bairro',
        number: 125,
        publicPlace: 'Logradouro',
        uf: 'SP'
    },
    {
        city: 'São Paulo',
        distributor: 'Distribuidor',
        neighborhood: 'Bairro',
        number: 125,
        publicPlace: 'Logradouro',
        uf: 'SP'
    },
    {
        city: 'São Paulo',
        distributor: 'Distribuidor',
        neighborhood: 'Bairro',
        number: 125,
        publicPlace: 'Logradouro',
        uf: 'SP'
    },
    {
        city: 'São Paulo',
        distributor: 'Distribuidor',
        neighborhood: 'Bairro',
        number: 125,
        publicPlace: 'Logradouro',
        uf: 'SP'
    },
    {
        city: 'São Paulo',
        distributor: 'Distribuidor',
        neighborhood: 'Bairro',
        number: 125,
        publicPlace: 'Logradouro',
        uf: 'SP'
    },
    {
        city: 'São Paulo',
        distributor: 'Distribuidor',
        neighborhood: 'Bairro',
        number: 125,
        publicPlace: 'Logradouro',
        uf: 'SP'
    },
    {
        city: 'São Paulo',
        distributor: 'Distribuidor',
        neighborhood: 'Bairro',
        number: 125,
        publicPlace: 'Logradouro',
        uf: 'SP'
    },
    {
        city: 'São Paulo',
        distributor: 'Distribuidor',
        neighborhood: 'Bairro',
        number: 125,
        publicPlace: 'Logradouro',
        uf: 'SP'
    },
    {
        city: 'São Paulo',
        distributor: 'Distribuidor',
        neighborhood: 'Bairro',
        number: 125,
        publicPlace: 'Logradouro',
        uf: 'SP'
    },
    {
        city: 'São Paulo',
        distributor: 'Distribuidor',
        neighborhood: 'Bairro',
        number: 125,
        publicPlace: 'Logradouro',
        uf: 'SP'
    },
    {
        city: 'São Paulo',
        distributor: 'Distribuidor',
        neighborhood: 'Bairro',
        number: 125,
        publicPlace: 'Logradouro',
        uf: 'SP'
    },
  ];
