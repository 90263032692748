import { estados } from "./options";
import { Box, Button, Container, Input, Label, Select } from "./styled";
import { FilterProps } from "./types";

export const Filter = (props: FilterProps) => {
  return (
    <Container>
      {props.type === "products" && (
        <>
          <Box>
            <Label htmlFor="getProduct">Produto</Label>
            <Input
              type="text"
              id="getProduct"
              name="getProduct"
              placeholder="Buscar por produto"
            />
          </Box>
          <Box>
            <Label htmlFor="getProductCategory">Categoria</Label>
            <Select id="getProductCategory" name="getProductCategory">
              <option value="">Selecione a Categoria</option>
              <option value="1">Casa</option>
              <option value="2">Esporte</option>
              <option value="3">Moda</option>
            </Select>
          </Box>
        </>
      )}
      {props.type === "distributors" && (
        <>
          <Box>
            <Label>Distribuidor</Label>
            <Input
              type="text"
              id="getProduct"
              name="getProduct"
              placeholder="Buscar por produto"
            />
          </Box>
          <Box>
            <Label>UF</Label>
            <Select>
              <option value="">Selecione a UF</option>
              {estados.map((option, key: number) => (
                <option key={key} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </Box>
          <Box>
            <Label>Cidade</Label>
            <Select>
              <option value="">Selecione a Cidade</option>
              <option value="1">Casa</option>
              <option value="2">Esporte</option>
              <option value="3">Moda</option>
            </Select>
          </Box>
        </>
      )}
      <Button>Pesquisar</Button>
    </Container>
  );
};
